import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

const Laminates = () => (
  <Layout>
    <SEO
      title="Laminates सिलेक्शन कैसे करे ?(How to select Laminates for wood work)"
      keyword={[
        "Laminates",
        "Laminate selection ",
        "Interior Design",
        "PVC Laminates",
        "Laminate Finish",
        "Laminate ka selection kaise kare",
        "Ghar ke interior ke liye laminate ka selection kaise kare",
        "PVC Kya best option hai"
      ]}
    />
    <Container>
      <center>
        <h2>Laminates</h2>
      </center>
      <Row>
        <Col lg={6}>
          <p>
            घर को एक अच्छा लुक देने में लेमिनेट्स का बड़ा रोल होता है अच्छे
            लेमिनेट्स का इस्तेमाल किया जाये तो घर के लुक को बदल देता है इससे
            अलमीरा, TV यूनिट, किचन वुड वर्क दूरबिलिटी बढ़ जाती है{" "}
          </p>
          <p>
            लेमिनेट्स के बहुत सारे ऑप्शन हैं आप Decolam का इस्तेमाल कर सकते हैं।
            PVC और Acrylic लेमिनेट्स का भी इस्तेमाल कर सकते हैं। Vineer भी
            इस्तेमाल कर सकते हैं लेकिन अगर आप अपने घर का हाई ग्लॉसी लुक देना
            चाहते हैं तो PVC आपके लिए एक अच्छा ऑप्शन हो सकता है अगर आप PVC के
            बारे में जानकारी चाहते है जैसे की कौन से जगह भी PVC बेस्ट ऑप्शन हो
            सकता ह। किन किन बात को आपको ध्यान में रखने की जरुरत है और PVC कौन से
            जगह पे इस्तेमाल बिलकुल नहीं करना चाहिए ये सारे जानकारी चाहते हैं तो
            आप ये वीडियो जरुरत देखे{" "}
          </p>
          1 ज्यादातर PVC लेमिनेट्स 90 डिग्री बेंड हो जाता है तो अगर आप स्किल्ड
          कारपेंटर से कराते हो तो ये बेंड आसानी से हो सकता है एक स्पेशल ब्लेड
          होता है जिसको उसे करके कारपेंटर ९०% बेंड कर सकता है जिससे आप एक अच्छी
          फिनिशिंग करा सकते हैं
        </Col>
        <Col lg={6} md={8}>
          <p>
            <strong>PVC Laminates</strong>
          </p>
          <div className="embed-responsive embed-responsive-16by9">
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/EOxjSlpGXc8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          अगर कारपेंटर को ज्यादा आईडिया नहीं हो ९०% बेंड करने का तो आप बिना ९०%
          बेंड किये भी इस्तेमाल कर सकते है। लेकिन उसका को एज होता है काफी हार्ड
          होता ह।। और चोट लगने पे काटने फटने के चांस रहता ह।। इसलिए जहा भी एज
          बिंडिंग का इस्तेमाल कर सकते हैं जरूर कीजिये
          <p>
            २) अगर PVC बेंड करते वक़्त आसानी से ब्रेक हो जाये तो वैसे PVC को
            अवॉयड कीजिये अच्छी क्वालिटी का नहीं होता ह।{" "}
          </p>
          ३) PVC के ऊपर से एक प्रोटेक्शन शीट आता है आप उसको तब तक न हटाए जब तक
          आपके घर का पूरा काम न हो जाये ४) PVC के लिए एक स्पेशल फेविकोल आता है
          नार्मल फेविकोल बिलकुल न इस्तेमाल कीजिय।। और स्पेशल फेविकोल इस्तेमाल
          करते वक़्त ये ध्यान रखे की कारपेंटर अच्छे से चिपका रहा है न एक बार PVC
          प्लाईवुड में पकड़ ले तो दुबारा चोर्ने के चांस काम होता है लेकिन अच्छे
          से न चिपकाया जाये तो धीरे धीरे बाहर आ सकता है ५) जब आप PVC सेलेक्ट
          करते है तो और मल्टीप्ल कलर उसे करते है तो थिकनेस पे आपको बहुत ही ध्यान
          देने की जरुरत है कभी कभी क्या होता है की लैमिनेट आपको १ mm का मिलता है
          कोई । १ और । २ मम का मिल जाता है तो इस कसे म बेकार दिखने लगता है ६ PVC
          कभी भी ऐसे जगह पे इस्तेमाल न करे जहा सनलाइट आता है आपका लेमिनेट्स १ से
          २ साल के बिच में ही डिस कलर होना स्टार्ट हो जाता है बाद में बहुत ही
          ख़राब दिखने लगता ह। ७)। कभी भी लैमिनेट का सिलेक्शन बिना सैंपल देखे न
          करे। मोबाइल में फोटो देख के कभी भी फाइनलीज़े न करे क्योकि मोबाइल में
          भेजा हुआ फोटो और एक्चुअल कलर में डिफरेंस हो सकता है ८) PVC का कॉस्ट
          varry करता है रेट १५००-३००० रस तक एक शीट का लगता है आप २-३ शॉप में
          प्राइस चेक करने के बाद ही ख़रीदे ९)। लैमिनेट का जो एज होता है लैमिनेट
          के मैचिंग मिले तो उसको प्रेफर करना चाहिए
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Laminates
